import React from "react";
import { Partner } from "../../models/Partner.ts";
import { notification } from "../../utils/Notifications.tsx";
import DocumentItem from "./DocumentItem.tsx";
import { getProceedingByPartner } from "../../services/proceedings.service.ts";
import { findTemplatesByPositionId } from "../../services/template.service.ts";
import Loader from "../Loader.tsx";
import S3Service from "../../services/s3.service.ts";
import { File, FileType } from "../../interfaces/others/ILegacyDocument.ts";

const TimeLineDocuments = ({
  partner,
  reloadPage,
}: {
  partner: Partner;
  reloadPage: any;
}) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [inmediate, setInmediate] = React.useState<any>([]);
  const [noInmediate, setNoInmediate] = React.useState<any>([]);
  const [legacyDocuments, setLegacyDocuments] = React.useState<FileType[]>([]);

  const loadFiles = async () => {
    try {
      const proceeding = await getProceedingByPartner(partner.id);
      const { position } = proceeding;
      const { templates } = await findTemplatesByPositionId(position.id);

      const Inmediate = templates[0].documents;
      const NoInmediate = templates[1].documents;

      setInmediate(Inmediate);
      setNoInmediate(NoInmediate);
      await fetchImages();
    } catch (error) {
      console.log(error);
      notification(
        "Error",
        "Ocurrió un error al recuperar los documentos",
        "danger"
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchImages = async () => {
    setLoading(true);
    const documentsTemps: FileType[] = [];
    try {
      const profileUrl = await S3Service.getFiles(
        "PERFIL_" + partner.person.curp
      );
      const perfil = setImages(profileUrl, "PERFIL_" + partner.person.curp);
      documentsTemps.push({
        typeFile: "Foto colaborador",
        files: perfil,
        name: "PERFIL_" + partner.person.curp,
      });

      const ineUrl = await S3Service.getFiles("INE_" + partner.person.curp);
      const ine1Url = await S3Service.getFiles("INE_1_" + partner.person.curp);
      const ine = setImages(ineUrl, "INE_" + partner.person.curp);
      const ine1 = setImages(ine1Url, "INE_1_" + partner.person.curp);
      documentsTemps.push({
        typeFile: "INE frente y vuelta",
        files: ine.concat(ine1),
        name: "INE_" + partner.person.curp,
      });

      const domicilioUrl = await S3Service.getFiles(
        "DOMICILIO_" + partner.person.curp
      );
      const domicilio = setImages(
        domicilioUrl,
        "DOMICILIO_" + partner.person.curp
      );
      documentsTemps.push({
        typeFile: "Comprobante de domicilio actual",
        files: domicilio,
        name: "DOMICILIO_" + partner.person.curp,
      });

      const solicitudUrl = await S3Service.getFiles(
        "SOLICITUD_" + partner.person.curp
      );
      const solicitud = setImages(
        solicitudUrl,
        "SOLICITUD_" + partner.person.curp
      );
      documentsTemps.push({
        typeFile: "Original solicitud de empleo",
        files: solicitud,
        name: "SOLICITUD_" + partner
      });

      const psicometricsUrl = await S3Service.getFiles(
        "PSICOMETRICO_" + partner.person.curp
      );
      const files: File[] = psicometricsUrl.map((url) => ({
        id: getIdImage(url?.url),
        url: url?.url,
      }));
      documentsTemps.push({
        typeFile: "Pruebas psicométricas aplicadas por reclutamiento",
        files,
        name: "PSICOMETRICO_" + partner
      });

      setLegacyDocuments(documentsTemps);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchImagesByName = async (name: string) => {
    setLoading(true);
    const documentsTemps: FileType[] = [...legacyDocuments]
    try {
      if (name.includes('PERFIL_')) {
        const profileUrl = await S3Service.getFiles(
          "PERFIL_" + partner.person.curp
        );
        const perfil = setImages(profileUrl, "PERFIL_" + partner.person.curp);
        const index = documentsTemps.findIndex((doc) => doc.typeFile === "Foto colaborador");
        if (index > -1) {
          documentsTemps.splice(index, 1);
        }
        documentsTemps.push({
          typeFile: "Foto colaborador",
          files: perfil,
        });
      } else if (name.includes('INE_')) {
        const ineUrl = await S3Service.getFiles("INE_" + partner.person.curp);
        const ine = setImages(ineUrl, "INE_" + partner.person.curp);
        const ine1Url = await S3Service.getFiles("INE_1_" + partner.person.curp);
        const ine1 = setImages(ine1Url, "INE_1_" + partner.person.curp);

        const index = documentsTemps.findIndex((doc) => doc.typeFile === "INE frente y vuelta");
        if (index > -1) {
          documentsTemps.splice(index, 1);
        }
        documentsTemps.push({
          typeFile: "INE frente y vuelta",
          files: ine.concat(ine1),
        });
      } else if (name.includes('DOMICILIO_')) {
        const domicilioUrl = await S3Service.getFiles(
          "DOMICILIO_" + partner.person.curp
        );
        const domicilio = setImages(
          domicilioUrl,
          "DOMICILIO_" + partner.person.curp
        );
        const index = documentsTemps.findIndex((doc) => doc.typeFile === "Comprobante de domicilio actual");
        if (index > -1) {
          documentsTemps.splice(index, 1);
        }
        documentsTemps.push({
          typeFile: "Comprobante de domicilio actual",
          files: domicilio,
        });
      } else if (name.includes('SOLICITUD_')) {
        const solicitudUrl = await S3Service.getFiles(
          "SOLICITUD_" + partner.person.curp
        );
        console.log(solicitudUrl);
        const solicitud = setImages(
          solicitudUrl,
          "SOLICITUD_" + partner.person.curp
        );
        const index = documentsTemps.findIndex((doc) => doc.typeFile === "Original solicitud de empleo");
        if (index > -1) {
          documentsTemps.splice(index, 1);
        }
        documentsTemps.push({
          typeFile: "Original solicitud de empleo",
          files: solicitud,
        });
      } else if (name.includes('PSICOMETRICO_')) {
        const psicometricsUrl = await S3Service.getFiles(
          "PSICOMETRICO_" + partner.person.curp
        );
        const files: File[] = psicometricsUrl.map((url) => ({
          id: getIdImage(url?.url),
          url: url?.url,
        }));
        const index = documentsTemps.findIndex((doc) => doc.typeFile === "Pruebas psicométricas aplicadas por reclutamiento");
        if (index > -1) {
          documentsTemps.splice(index, 1);
        }
        documentsTemps.push({
          typeFile: "Pruebas psicométricas aplicadas por reclutamiento",
          files,
        });
      }
      setLegacyDocuments(documentsTemps);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const setImages = (imagenes: string[], originalField: string): File[] => {
    const originalIndex = imagenes?.findIndex(
      (record: any) => getIdImage(record?.url) === originalField
    );

    // FOTO_ROBERTO - original
    // FOTO_ROBERTO_1 - hijos
    // FOTO_ROBERTO_2 - hijos
    // FOTO_ROBERTO_3 - hijos

    const images = imagenes.map((record: any, index: number) => {
      return {
        id: getIdImage(record?.url),
        url: record?.url,
        original: index === originalIndex,
      };
    });

    return images;
  };

  const getIdImage = (url: string): string => {
    const pathParts = url.split("/");
    const lastPart = pathParts[pathParts.length - 1];
    const id = lastPart.split("?")[0];
    return id;
  };

  React.useEffect(() => {
    loadFiles();
  }, []);

  return (
    <div className="tab-pane fade " id="expediente">
      {/* {JSON.stringify(legacyDocuments)} */}
      <div className="timeline">
        {inmediate.map((record, index) => (
          <DocumentItem
            partnerId={partner.id}
            partnerObject={partner}
            file={record}
            loadFiles={loadFiles}
            key={index}
            documentsArray={legacyDocuments}
            reloadPage={reloadPage}
            fetchImages={fetchImages}
            fetchImagesByName={fetchImagesByName}
          />
        ))}
        {noInmediate.map((record, index) => (
          <DocumentItem
            partnerId={partner.id}
            partnerObject={partner}
            file={record}
            loadFiles={loadFiles}
            key={index}
            documentsArray={legacyDocuments}
            reloadPage={reloadPage}
            fetchImages={fetchImages}
            fetchImagesByName={fetchImagesByName}
          />
        ))}
      </div>
      <Loader isLoading={loading} />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default TimeLineDocuments;
