import React from "react";
import { findConfigurationByKey } from "../../services/configuration.service.ts";
import ModalContainer from "../ModalContainer.tsx";
import EditTimeAppoinment from "../config/EditTimeAppoinment.tsx";
import Loader from "../Loader.tsx";
import EditCheckTime from "../config/EditCheckTime.tsx";
import EditOrderPosition from "../config/EditOrderPosition.tsx";
import { readPositions } from "../../services/position.service.ts";
import { useNavigate } from "react-router-dom";

const ConfigSection = () => {
  const [appoinmentTime, setAppoinmentTime] = React.useState(10);
  const [modalAppoinment, setModalAppoinment] = React.useState<boolean>(false);

  const [assisitanceTime, setAssisitanceTime] = React.useState();
  const [modalAssisitance, setModalAssisitance] =
    React.useState<boolean>(false);
  const [modalOrderPosition, setModalOrderPosition] =
    React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);

  const fetchDependencies = async () => {
    setLoading(true);
    try {
      const appoinmentTimeResponse = await findConfigurationByKey(
        "Appointment"
      );
      setAppoinmentTime(appoinmentTimeResponse?.value?.minutes || 10);

      const assistanceTimeResponse = await findConfigurationByKey("assistance");
      if (assistanceTimeResponse?.value)
        setAssisitanceTime(assistanceTimeResponse?.value);
    } catch (error) {
      setAppoinmentTime(10);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const [positions, setPositions] = React.useState([]);

  const fetchPositions = async () => {
    setLoading(true);
    try {
      const response = await readPositions();
      setPositions(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchDependencies();
    fetchPositions();
  }, []);

  const navigate = useNavigate();

  const handleOrderPosition = () => {
    navigate("order-puestos/", {
      state: { positionsList: positions },
    });
  };

  return (
    <div className="col-xl-8" id="bsSpyContent">
      <div id="general" className="mb-4 pb-3">
        <h4 className="d-flex align-items-center mb-2">
          <i className="fa-solid fa-gear"></i>&nbsp; General
        </h4>
        <p>Ver y actualizar la configuración general del sistema.</p>
        <div className="card">
          <div className="list-group list-group-flush fw-bold">
            <div className="list-group-item d-flex align-items-center">
              <div className="flex-fill">
                <div>Tiempo por cita</div>
                <div className="text-body text-opacity-60">
                  {appoinmentTime} minutos
                </div>
              </div>
              <div className="w-100px">
                <a
                  className="btn btn-secondary w-100px"
                  onClick={() => setModalAppoinment(true)}
                >
                  Editar
                </a>
              </div>
            </div>
            <div className="list-group-item d-flex align-items-center">
              <div className="flex-fill">
                <div>Minutos para registrar asistencia</div>
                <div className="text-body text-opacity-60">
                  {appoinmentTime} minutos
                </div>
              </div>
              <div className="w-100px">
                <a
                  className="btn btn-secondary w-100px"
                  onClick={() => setModalAssisitance(true)}
                >
                  Editar
                </a>
              </div>
            </div>
          </div>
        </div>
        <Loader isLoading={loading} />
      </div>
      <div id="general" className="mb-4 pb-3">
        <h4 className="d-flex align-items-center mb-2">
          <i className="fa-solid fa-gear"></i>&nbsp; Ordenamiento General
        </h4>
        <p>Ordenamiento visualizadas</p>
        <div className="card">
          <div className="list-group list-group-flush fw-bold">
            <div className="list-group-item d-flex align-items-center">
              <div className="flex-fill">
                <div>Orden de puestos</div>
                <div className="text-body text-opacity-60">
                  Drag and drop para ordenar los puestos
                </div>
              </div>
              <div className="w-100px">
                <a
                  className="btn btn-secondary w-100px"
                  //go to handleOrderPosition
                  onClick={handleOrderPosition}
                >
                  Editar
                </a>
              </div>
            </div>
          </div>
        </div>
        <Loader isLoading={loading} />
      </div>
      <div id="general" className="mb-4 pb-3">
        <h4 className="d-flex align-items-center mb-2">
          <i className="fa-solid fa-medal"></i>&nbsp; Insignias
        </h4>
        <p>Panel de insignias para trabajadores</p>
        <div className="card">
          <div className="list-group list-group-flush fw-bold">
            <div className="list-group-item d-flex align-items-center">
              <div className="flex-fill">
                <div>Ver todas las insignias </div>
                <div className="text-body text-opacity-60"></div>
              </div>
              <div className="w-100px">
                <a
                  className="btn btn-secondary w-100px"
                  onClick={() => navigate("/insignias")}
                >
                  Panel
                </a>
              </div>
            </div>
          </div>
        </div>
        <Loader isLoading={loading} />
      </div>
      <ModalContainer
        title="Editar el tiempo de la cita"
        open={modalAppoinment}
        closeFn={() => setModalAppoinment(false)}
        children={
          <EditTimeAppoinment
            closeFn={() => setModalAppoinment(false)}
            refreshFn={() => fetchDependencies()}
            currentTime={appoinmentTime}
            setLoading={(value) => setLoading(value)}
          />
        }
      />
      <ModalContainer
        title="Editar los minutos para registrar asistencia"
        open={modalAssisitance}
        closeFn={() => setModalAssisitance(false)}
        children={
          <EditCheckTime
            closeFn={() => setModalAssisitance(false)}
            refreshFn={() => fetchDependencies()}
            setLoading={(value) => setLoading(value)}
            assisitanceTime={assisitanceTime}
          />
        }
      />
    </div>
  );
};

export default ConfigSection;
