import React, { useEffect } from 'react';
import EmptyImage from '../../../../assets/img/ic_content.svg';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import S3Service from '../../../../services/s3.service.ts';
import { useFormik } from 'formik';
import { updateAssistance, updateReplacement } from '../../../../services/branchSchedule.service.ts';
import { notification } from '../../../../utils/Notifications.tsx';
import ModalContainer from '../../../ModalContainer.tsx';
import CustomSelectField from '../../../formik/CustomSelectField.tsx';
import CustomInput from '../../../formik/CustomInput.tsx';
import { toCamelCase } from '../../../../hooks/toCameCase.tsx';

const TableIncidencias = ({ refetch, completePartners, partners, schedules, checks }) => {
  const [profiles, setProfiles] = React.useState<any>([]);

  useEffect(() => {
    const fetchProfile = async () => {
      let profiles = await Promise.all(
        partners.map(async (partner) => {
          try {
            const profile = await S3Service.getFile("PERFIL_" + partner.person.curp);
            return { partnerId: partner.id, profile };
          } catch (error) {
            console.error("Error fetching profile for partner:", partner.id, error);
            return { partnerId: partner.id, profile: null };
          }
        })
      );

      await Promise.all(
        schedules.map(async (schedule) => {
          if (schedule.replacement) {
            try {
              const profile = await S3Service.getFile("PERFIL_" + schedule.replacement.partner.person.curp);
              profiles.push({ partnerId: schedule.replacement.partner.id, profile });
            } catch (error) {
              console.error("Error fetching profile for replacement:", schedule.replacement.partner.id, error);
            }
          }
        }
      ));

      console.log('profiles', profiles);
      
      setProfiles(profiles);
    };

    if (partners.length) fetchProfile();
  }, [partners]);

  const initialValues = {
    assistanceCheck: {},
    assistanceCheckout: {},
    selectedStatus: {},
    actualPartner: '',
    newPartner: -99
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const objects = Object.keys(values.assistanceCheck).map((key) => {
        const assistanceCheck = values.assistanceCheck[key];
        const assistanceCheckout = values.assistanceCheckout[key];
        const assistanceStatus = values.selectedStatus[key];
        const scheduleId = key;

        return { assistanceCheck, assistanceCheckout, assistanceStatus, scheduleId };
      });

      try {
        await Promise.all(objects.map(async (object) => {
          try {
            await updateAssistance(Number(object.scheduleId), object);
          } catch (error) {
            console.error('Error updating assistance', error);
          }
        }));

        refetch();
        notification('Se actualizaron las asistencias correctamente', 'Asistencias actualizadas', 'success');
      } catch (error) {
        console.error('Error updating assistance', error);
        notification('Ocurrió un error al actualizar las asistencias', 'Error al actualizar asistencias', 'danger');
      }
    }
  });

  useEffect(() => {
    partners.forEach((partner) => {
      const partnerSchedules = schedules?.filter((schedule) => schedule.partner.id === partner.id);

      partnerSchedules.forEach((schedule) => {
        formik.setFieldValue(`assistanceCheck.${schedule.id}`, schedule.assistanceCheck?.id || '');
        formik.setFieldValue(`assistanceCheckout.${schedule.id}`, schedule.assistanceCheckout?.id || '');
        formik.setFieldValue(`selectedStatus.${schedule.id}`, schedule.assistanceStatus || '');
      });
    });
  }, [partners, schedules]);

  const [changeModal, setChangeModal] = React.useState(false);
  const [userToBeChanged, setUserToBeChanged] = React.useState<any>(null);

  const getStatusIconClass = (status) => {
    switch (status) {
      case 'Asistencia':
        return 'fa-solid fa-circle-check text-success';
      case 'Fuera de horario':
        return 'fa-solid fa-circle-minus text-secondary';
      case 'Retardo':
        return 'fa-solid fa-circle-xmark text-danger';
      case 'Sin registro':
        return 'fa-solid fa-circle-question text-warning';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (userToBeChanged) {
      formik.setFieldValue('actualPartner', userToBeChanged.partner.person.firstName + ' ' + userToBeChanged.partner.person.lastName);
      formik.setFieldValue('newPartner', userToBeChanged.replacement?.partner.id || -99);
    }
  }, [userToBeChanged]);

  const replaceUser = async (scheduleId, replacementUserId) => {
    try {

      await updateReplacement(scheduleId, { replacement: replacementUserId });
      refetch();
      notification('Reemplazo realizado', 'Se realizó el reemplazo correctamente', 'success');
    } catch (error) {
      console.error('Error updating replacement', error);
      notification('Ocurrió un error al realizar el reemplazo', 'Error al realizar el reemplazo', 'danger');
    } finally {
      setChangeModal(false);
      setUserToBeChanged(null);
    }
  }

  console.log('formik', schedules);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="table-sticky mb-3">
        <table className="table table-hover table-panel text-nowrap align-middle mb-0">
          <thead className="sticky-thead table-light text-center">
            <tr>
              <th rowSpan={2} className="align-middle">Id</th>
              <th rowSpan={2} className="align-middle">Colaborador</th>
              <th colSpan={2} className="align-middle">Horario Planeado</th>
              <th colSpan={2} className="align-middle">Incidencias</th>
              <th rowSpan={2} className="align-middle">Minutos Extra</th>
              <th rowSpan={2} className="align-middle">Tipo</th>
              <th rowSpan={2} className="align-middle">Acción</th>
            </tr>
            <tr>
              <th>Entrada</th>
              <th>Salida</th>
              <th>Entrada</th>
              <th>Salida</th>
            </tr>
          </thead>
          <tbody>
            {partners.length ? (
              partners.map((partner, index) => {
                const partnerSchedules = schedules.filter((schedule) => schedule.partner.id === partner.id);
                const partnerChecks = checks.filter((check) => check.partner.id === partner.id) || [];

                return (
                  partnerSchedules.length ? (
                    partnerSchedules.map((schedule, scheduleIndex) => {
                      const diffMinutes = moment(schedule.assistanceCheckout?.checkTime, 'HH:mm').diff(
                        moment(schedule.endTime.split(' - ')[1], 'HH:mm'),
                        'minutes'
                      );

                      const result =
                        diffMinutes > 0
                          ? `${diffMinutes} minutos después`
                          : diffMinutes < 0
                            ? `${Math.abs(diffMinutes)} minutos antes`
                            : 'Exactamente a la hora de salida';

                      const finalResult = isNaN(diffMinutes) ? 'N/A' : result;

                      console.log('partnerChecks', partner);

                      return (
                        <tr key={index} className="text-center">
                          <td>{partner.id}</td>
                          <td>
                            {
                              schedule.replacement?.id ? (
                                <>
                                  <div className="d-flex flex-row justify-content-between align-items-center">
                                    <div>
                                      <span className="text-success">Original1</span>
                                      <div className="d-flex flex-column align-items-center justify-content-center">
                                        <img
                                          src={profiles.find((profile) => profile.partnerId === partner.id)?.profile?.url || ''}
                                          alt={partner.person.firstName}
                                          className="rounded-circle"
                                          style={{ width: 50, height: 50 }}
                                        />
                                        <span className="mt-2">
                                          {partner.person.firstName} {partner.person.lastName} {partner.person.secondLastName}
                                        </span>
                                        <span className="text-secondary">
                                          {schedule.positionName} - {schedule.shift}
                                        </span>
                                      </div>
                                    </div>
                                    <i className="fa-solid fa-repeat fs-2"></i>
                                    <div>
                                      <span className="text-warning">Reemplazo</span>
                                      <div className="d-flex flex-column align-items-center justify-content-center">
                                        <img
                                          src={profiles.find((profile) => profile.partnerId === schedule.replacement?.partner.id)?.profile?.url || ''}
                                          alt={partner.replacement?.partner.person.firstName}
                                          className="rounded-circle"
                                          style={{ width: 50, height: 50 }}
                                        />
                                        <span className="mt-2">
                                          {toCamelCase(`${schedule.replacement?.partner.person.firstName} ${schedule.replacement?.partner.person.lastName} ${schedule.replacement?.partner.person.secondLastName}`)}
                                        </span>
                                        <span className="text-secondary">
                                          {schedule.positionName} - {schedule.shift}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                  <img
                                    src={profiles.find((profile) => profile.partnerId === partner.id)?.profile?.url || ''}
                                    alt={partner.person.firstName}
                                    className="rounded-circle"
                                    style={{ width: 50, height: 50 }}
                                  />
                                  <span className="mt-2">
                                    {partner.person.firstName} {partner.person.lastName} {partner.person.secondLastName}
                                  </span>
                                  <span className="text-secondary">
                                    {schedule.positionName} - {schedule.shift}
                                  </span>
                                </div>
                              )
                            }
                          </td>
                          <td>{schedule.startTime.split(' - ')[0]}</td>
                          <td>{schedule.endTime.split(' - ')[1]}</td>
                          {partnerChecks.length ? (
                            <>
                              <td>
                                <select
                                  className="form-select"
                                  value={formik.values.assistanceCheck[schedule.id] || ''}
                                  onChange={(e) =>
                                    formik.setFieldValue(`assistanceCheck.${schedule.id}`, e.target.value)
                                  }
                                >
                                  <option value="">Selecciona</option>
                                  {partnerChecks.map((check, checkIndex) => (
                                    <option key={checkIndex} value={check.id}>
                                      {check.checkTime}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <select
                                  className="form-select"
                                  value={formik.values.assistanceCheckout[schedule.id] || ''}
                                  onChange={(e) =>
                                    formik.setFieldValue(`assistanceCheckout.${schedule.id}`, e.target.value)
                                  }
                                >
                                  <option value="">Selecciona</option>
                                  {partnerChecks.map((check, checkIndex) => (
                                    <option key={checkIndex} value={check.id}>
                                      {check.checkTime}
                                    </option>
                                  ))}
                                </select>
                              </td>
                            </>
                          ) : (
                            <>
                              <td>N/A</td>
                              <td>N/A</td>
                            </>
                          )}
                          <td>{finalResult}</td>
                          <td>{schedule.assistanceStatus || 'N/A'}</td>
                          <td>
                            {['Asistencia', 'Fuera de horario', 'Retardo', 'Sin registro'].map((status, statusIndex) => {
                              const isStatusActive = formik.values.selectedStatus[schedule.id] === status;
                              const iconClass = getStatusIconClass(status);

                              const hasCheck = partnerChecks.length;

                              const iconOpacity = !hasCheck && status === 'Sin registro' ? '' : !isStatusActive && 'opacity-25';

                              return (
                                <Tooltip
                                  key={statusIndex}
                                  title={status}
                                  arrow
                                >
                                  <button
                                    type="button"
                                    className={`btn p-0 ${iconOpacity} ${!partnerChecks.length ? 'disabled-btn' : ''}`}
                                    onClick={() => partnerChecks.length && formik.setFieldValue(`selectedStatus.${schedule.id}`, status)}
                                    style={{ border: 'none', cursor: !partnerChecks.length ? 'not-allowed' : 'pointer' }}
                                  >
                                    <i className={`${iconClass} fs-1 mx-2`}></i>
                                  </button>
                                </Tooltip>
                              );
                            })}
                            <Tooltip
                              title='Cambiar'
                              arrow
                            >
                              <button
                                type="button"
                                className={`btn p-0`}
                                onClick={() => {
                                  setChangeModal(true)
                                  setUserToBeChanged(schedule);
                                }}
                                style={{ border: 'none', cursor: partnerChecks.length ? 'not-allowed' : 'pointer' }}
                              >
                                <i className={`fa-solid fa-circle-arrow-right fs-1 mx-2 text-indigo-600`}></i>
                              </button>
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <>
                      {
                        Array.from({ length: 6 }).map((_, emptyIndex) => <td key={emptyIndex}>N/A</td>)
                      }
                      <td>
                        {['Asistencia', 'Fuera de horario', 'Retardo', 'Sin registro'].map((status, statusIndex) => {
                          const isStatusActive = formik.values.selectedStatus[partner.id] === status;
                          const iconClass = getStatusIconClass(status);

                          const hasCheck = partnerChecks.length;

                          const iconOpacity = !hasCheck && status === 'Sin registro' ? '' : !isStatusActive && 'opacity-25';

                          return (
                            <Tooltip
                              key={statusIndex}
                              title={status}
                              arrow
                            >
                              <button
                                type="button"
                                className={`btn p-0 ${iconOpacity} ${!partnerChecks.length ? 'disabled-btn' : ''}`}
                                onClick={() => partnerChecks.length && formik.setFieldValue(`selectedStatus.${partner.id}`, status)}
                                style={{ border: 'none', cursor: !partnerChecks.length ? 'not-allowed' : 'pointer' }}
                              >
                                <i className={`${iconClass} fs-1 mx-2`}></i>
                              </button>
                            </Tooltip>

                          );
                        })}
                        <Tooltip
                          title='Cambiar'
                          arrow
                        >
                          <button
                            type="button"
                            className={`btn p-0`}
                            onClick={() => { }}
                            style={{ border: 'none', cursor: !partnerChecks.length ? 'not-allowed' : 'pointer' }}
                          >
                            <i className={`fa-solid fa-circle-arrow-right fs-1 mx-2`}></i>
                          </button>
                        </Tooltip>
                      </td>
                    </>
                  )
                );
              })
            ) : (
              <tr>
                <td colSpan={10}>
                  <div className="py-4">
                    <div className="d-flex">
                      <img src={EmptyImage} alt="" className="mx-auto w-250px" />
                    </div>
                    <h5 className="text-secondary text-center fs-20px">Aún no existen datos</h5>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <button type="submit" className="btn btn-primary">Guardar cambios</button>
      <ModalContainer
        open={changeModal}
        closeFn={() => {
          setChangeModal(false);
          setUserToBeChanged(null);
        }}
        title={`Cubrir puesto de ${userToBeChanged?.positionName}`}
      >
        <CustomInput
          field='actualPartner'
          label='Original'
          formik={formik}
          sm={9}
          unclickable
          disabled
        />
        <CustomSelectField
          field='newPartner'
          label='Nuevo'
          formik={formik}
          list={
            completePartners
              .filter((partner) => partner.id !== userToBeChanged?.partner.id)
              .map((partner) => ({ id: partner.id, name: toCamelCase(partner.person.firstName + ' ' + partner.person.lastName) }))
          }
        />
        <div className="d-flex flex-row justify-content-end">
          <button type="button" className="btn btn-secondary me-2" onClick={() => {
            setChangeModal(false);
            setUserToBeChanged(null);
          }}>Cancelar</button>
          <button type="button" className="btn btn-primary" onClick={() =>
            replaceUser(userToBeChanged?.id, formik.values.newPartner)
          }>Guardar</button>
        </div>
      </ModalContainer>

    </form >
  );
};

export default TableIncidencias;
