import { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { deepParseJson } from '../utils/index.ts';

const SERVER_URL = process.env.REACT_APP_API_URL;

export const useWebSocket = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [update, setUpdate] = useState(false);
  const socketRef = useRef<any>(null);

  const initializeSocket = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));

    const rawPersistData = localStorage.getItem("persist:root");

    console.log('rawPersistData', rawPersistData);

    if (!rawPersistData) {
      console.error("No se encontraron datos en localStorage.");
      return;
    }

    const persistData = deepParseJson(rawPersistData);
    const token = persistData?.auth?.token;

    if (!token) {
      console.error("Token no encontrado.");
      return;
    }

    const options = {
      transportOptions: {
        polling: {
          extraHeaders: {
            dataconnection: token,
          },
        },
      },
      path: '/chili-socket',
      extraHeaders: {
        dataconnection: token,
      },
    };

    socketRef.current = io(SERVER_URL, options);

    socketRef.current.on('connect', () => {
      console.log('Conectado al servidor de WebSocket');
      setIsConnected(true);
    });

    socketRef.current.on('disconnect', () => {
      console.log('Desconectado del servidor de WebSocket');
      setIsConnected(false);
    });

    socketRef.current.on('usuarios-conectados', (message) => {
      console.log('Mensaje del servidor:', message);
    });
  };

  useEffect(() => {
    initializeSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  const triggerUpdate = ({ branchId }: { branchId: number }) => {
    console.log('triggerUpdate REQUESTED', branchId, socketRef.current?.connected);
    if (socketRef.current?.connected) {
      console.log('triggerUpdate SENT', branchId);
      socketRef.current.emit('triggerUpdate', { branchId });
    }
  };

  const assistancesObserver = (callback: (data: { branchId: number }) => void) => {
    const handleUpdate = (data: { branchId: number }) => {
      setUpdate(prev => !prev);
      callback(data);
    };

    if (!!socketRef.current) {
      socketRef.current.on('update', handleUpdate);
    }

    return () => {
      if (!!socketRef.current) {
        socketRef.current.off('update', handleUpdate);
      }
    };
  };

  return { isConnected, update, triggerUpdate, assistancesObserver };
};