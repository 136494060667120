import React from 'react'
import Layout from '../../../Layout.tsx'
import { useAppSettings } from '../../../../hooks/useAppSettings.tsx'
import { useParams } from 'react-router-dom'
import { readPartnersByBranch } from '../../../../services/partner.service.ts'
import Title from '../../../Title.tsx'
import TableIncidencias from './TableIncidencias.tsx'
import moment from 'moment'
import { findByExactDateAndBranch as findByDate } from '../../../../services/branchSchedule.service.ts'
import { findByExactDateAndBranch } from '../../../../services/assistanceCheck.service.ts'
import { findConfigurationByKey } from '../../../../services/configuration.service.ts'

const Incidencias = () => {
  useAppSettings()

  const { id } = useParams()
  const [loading, setLoading] = React.useState(false)
  const [partners, setPartners] = React.useState([])
  const [completePartners, setCompletePartners] = React.useState([])
  const [schedules, setSchedules] = React.useState([])
  const [checks, setChecks] = React.useState([])
  const today = moment().format('YYYY-MM-DD')

  const fetchDependencies = async () => {
    const config = await findConfigurationByKey("assistance");
    const { late, onTime } = config.value

    console.log("config", { late, onTime });

    const partners = await readPartnersByBranch(Number(id))
    setCompletePartners(partners)

    const schedules = await findByDate(today, Number(id))

    const checks = await findByExactDateAndBranch(today, Number(id))
    setChecks(checks)

    const filteredSchedules = schedules.filter((schedule) => {
      if (!schedule.assistanceCheck || !schedule.assistanceCheckout || (schedule.isReplacement === true) || !schedule.assistanceStatus || schedule.assistanceStatus === 'Fuera de horario') {
        return true;
      }
      return false;
    })
    setSchedules(filteredSchedules)

    const filteredPartners = partners.filter((partner) => {
      const schedule = schedules.find((schedule) => schedule.partner.id === partner.id && schedule.isReplacement === false);

      return !!schedule;
    });

    setPartners(filteredPartners)
    setLoading(false)
  }

  React.useEffect(() => {
    fetchDependencies()
  }, [])

  return (
    <Layout loading={loading}>
      <Title
        baseTitle="Sucursales"
        basePath="/sucursales"
        activeTitle={`Incidencias del día`}
        title={`Incidencias del día`}
      />
      <div className="card border-0 m-4 rounded">
        <div className="tab-content p-3">
          <TableIncidencias refetch={fetchDependencies} completePartners={completePartners} partners={partners} schedules={schedules} checks={checks} />
        </div>
      </div>
    </Layout>
  )
}

export default Incidencias