import React from "react";
import EmptyImagePath from "../assets/img/ic_content.svg";


const EmptyImage = ({ className }: { className: string }) => {
  return (
    <React.Fragment>
      <img src={EmptyImagePath} alt="" className={className} />
    </React.Fragment>
  );
};

export default EmptyImage;
