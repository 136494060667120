import React, { useEffect, useRef, useState } from "react";
import { Tooltip, Typography } from "@mui/material";
import { compactLayout, mergePositions } from "../../../utils/index.ts";
import GridLayout from "react-grid-layout";
import {
  generateGradientBackground,
  getBackgroundColor,
  isOverlapping,
  scheduleModule,
} from "../../../utils/constanst.js";
import { HourRange } from "../../../models/HourRange.ts";

const ScheduleCompact = ({
  hours,
  existingsSchedules,
  formik,
  positionsArray,
  setPositionsArray,
  selectedWeek,
  selectedDay = 0,
  turno = 0,
  hoursRange,

  layout,
  deleteItem,
  handleResize,
  handleResizeStop,
  onDragStop,
  setLastItem,
}) => {
  const [percentage, setPercentage] = useState(0);
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    calculatePercentage();
    const interval = setInterval(() => {
      calculatePercentage();
    }, 60000); // Every minute
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const calculatePercentage = () => {
    const hours = hoursRange;
    const percentageTotal = 100 / hours.length - scheduleModule;
    // const percentageTotal = 3.028
    // console.log(percentageTotal)
    let currentHour: string | number = new Date().getHours();
    const currentMinute = new Date().getMinutes();
    // let currentHour: string | number = 20
    // const currentMinute = 15

    currentHour = currentHour < 10 ? `0${currentHour}` : currentHour;

    const hourInFormat = `${currentHour}:${currentMinute < 30 ? "00" : "30"}`;

    const currentIndex =
      hours.findIndex((item: HourRange) => {
        if (item.display === "Hora") return false;

        const [start, end] = item.display.split(" - ");

        return hourInFormat >= start && hourInFormat < end;
      }) - 2;

    if (currentIndex < 0) return setPercentage(0);

    // Calculate the percentage of the current hour
    const percentage = currentIndex * percentageTotal;

    // Calculate the percentage of the current minute within the current half-hour block
    const percentageMinute = ((currentMinute % 30) / 30) * 3.01912655971;

    setPercentage(percentage + percentageMinute);
  };

  useEffect(() => {
    if (firstRender && percentage > 0) {
      goToFilterData();
    }
  }, [percentage]);

  const goToFilterData = () => {
    const element: HTMLElement | null = document.getElementById("lineaActual");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
    setFirstRender(false);
  };

  return (
    <div className="d-flex flex-row" style={{ width: 2400 }}>
      {/* <button onClick={() => calculatePercentage()}>xd</button> */}
      <div
        style={{
          position: "sticky",
          left: -10,
          zIndex: 6,
          backgroundColor: "white",
        }}
      >
        {mergePositions(positionsArray)
          .filter((position) => !position.isDeleted)
          .map((key, index) => {
            return (
              <div
                key={index}
                data-grid={{
                  x: 0,
                  y: index,
                  w: key?.title ? 36 : 2,
                  h: 1,
                  static: true,
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "left",
                  borderRight: "2px solid #EEE",
                  borderBottom: `${key?.title ? "3px" : "2px"} solid #EEE`,
                  borderTop: `${key?.title ? "3px solid" : ""} ${
                    key?.title === "Matutino"
                      ? "#FFD700"
                      : key.title === "Vespertino"
                      ? "#FF6347"
                      : "#4682B4"
                  }`,
                  paddingInline: "5px",
                  fontWeight: 600,
                  height: 50,
                  borderLeft: `3px solid ${
                    !key?.shifts
                      ? key.shift === "Matutino"
                        ? "#FFD700"
                        : key.shift === "Vespertino"
                        ? "#FF6347"
                        : "#4682B4"
                      : "#4682B4"
                  }`,
                  backgroundColor: "transparent",
                  width: (2400 / hoursRange?.length) * 2,
                }}
              >
                {key.title && (
                  <p className="w-100 mt-3 fs-15px fw-800 bg">
                    {key?.title ?? key.positionName}
                  </p>
                )}
                <Tooltip title={key?.shifts?.join(", ") ?? ""}>
                  <p className="w-100 mt-auto">{key?.positionName}</p>
                </Tooltip>
              </div>
            );
          })}
      </div>

      <div
        style={{
          position: "relative",
          cursor: "not-allowed",
          // border: "1px solid red",
          // overflowX: "auto",
        }}
      >
        <GridLayout
          // className="layout"
          cols={hoursRange.length}
          layout={compactLayout(layout, mergePositions(positionsArray))}
          maxRows={mergePositions(positionsArray)?.length}
          rowHeight={50}
          width={2400}
          preventCollision={false}
          allowOverlap={true}
          isResizable={false}
          isDroppable={false}
          draggable={false}
          onResize={handleResize}
          onResizeStop={handleResizeStop}
          onDragStop={onDragStop}
          compactType={null}
          margin={[0, 0]}
          style={{
            background: generateGradientBackground(hoursRange, hours, turno),
            width: `${2400}px`,
            position: "relative",
          }}
          onDrop={(layoutx, layoutItem, _event) => {
            setLastItem(layoutItem);
          }}
        >
          {mergePositions(positionsArray)
            .filter((position) => !position.isDeleted)
            .map((key, index) => {
              return (
                <div
                  key={index}
                  data-grid={{
                    x: 0,
                    y: index,
                    w: key?.title ? 36 : 2,
                    h: 1,
                    static: true,
                  }}
                  style={{
                    fontSize: "10px",
                    display: "none",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "left",
                    borderRight: "2px solid #EEE",
                    borderBottom: `${key?.title ? "3px" : "2px"} solid #EEE`,
                    borderTop: `${key?.title ? "3px solid" : ""} ${
                      key?.title === "Matutino"
                        ? "#FFD700"
                        : key.title === "Vespertino"
                        ? "#FF6347"
                        : "#4682B4"
                    }`,
                    paddingInline: "5px",
                    fontWeight: 600,
                    borderLeft: `3px solid ${
                      key.shift === "Matutino"
                        ? "#FFD700"
                        : key.shift === "Vespertino"
                        ? "#FF6347"
                        : "#4682B4"
                    }`,
                    backgroundColor: "white",
                    position: "sticky",
                    left: 0,
                    zIndex: 3,
                  }}
                >
                  {key.title && (
                    <p className="w-100 mt-3 fs-15px fw-800 bg">
                      {key?.title ?? key.positionName}
                    </p>
                  )}
                  <p className="w-100 mt-auto">{key?.positionName}</p>
                </div>
              );
            })}

          {compactLayout(layout, mergePositions(positionsArray)).map(
            (item, index) => {
              if (item?.x === undefined || !item?.show) return <></>;
              return (
                <div
                  key={item?.i}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "not-allowed",
                  }}
                  data-grid={{
                    x: item?.x,
                    y: item?.y,
                    w: item?.w,
                    h: 1,
                    // static: true,
                  }}
                >
                  <div
                    style={{
                      paddingLeft: "8px",
                      width: "100%",
                      borderRadius: "5px",
                      paddingBlock: "4px",
                      ...getBackgroundColor(item, index, positionsArray),
                    }}
                    className="shadow-sm"
                  >
                    <Tooltip
                      title={`${item.name} | ${item.position} | ${
                        item.w / 2
                      } hrs`}
                      arrow
                    >
                      <div className="d-flex flex-row">
                        {/* <div
              onMouseDown={(e) => e.stopPropagation()}
              className="position-absolute m-2"
              style={{
                left: 0,
                top: "35%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
              onClick={() => deleteItem(item?.i)}
            >
              <i className="fa-solid fa-xmark"></i>
            </div> */}
                        <Typography
                          fontWeight={500}
                          // marginLeft={1.5}
                        >
                          {item?.name?.split(" ")[0]}
                        </Typography>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              );
            }
          )}
        </GridLayout>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            // backgroundColor: "rgba(255, 255, 255, 0.6)",
            zIndex: 1,
          }}
        />

        <div
          id="lineaActual"
          style={{
            position: "absolute",
            top: 0,
            // left: "",
            left: `${percentage}%`,
            height: "100%",
            borderLeft: "2px solid red",
            zIndex: 2,
          }}
        />
      </div>
    </div>
  );
};

export default ScheduleCompact;
