import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import EmptyImage from "../../../assets/img/ic_content.svg";
import ModalContainer from "../../ModalContainer.tsx";
import Form from "../Form.tsx";
import { useNavigate } from "react-router-dom";
import EditTime from "./EditTime.tsx";
import { Tooltip } from "@mui/material";
import EditBranchMessage from "./componentes/EditBranchMessage.tsx";
import { useWebSocket } from "../../../hooks/useWebSocket.tsx";
import { setDefaultSort, setPage, setSortBy } from "../../../store/slices/sucursalesSlice.ts";
import { fixedHeadStyle, fixedStyle } from "../../../utils/constanst.js";
interface DataObject {
  id?: number;
  name?: string;
  isActive?: boolean;
  candidates?: Array<any>;
}

const TableSucursales = ({ user, refetch }) => {
  const allowed = user.rol !== "Lider de sucursal";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [record, setRecord] = useState<DataObject>();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditTimeModal, setShowEditTimeModal] = useState<boolean>(false);
  const [branchId, setBranchId] = useState<number>(-99);
  const [modalText, setModalText] = useState<boolean>(false);

  const { sucursalesList, sortBy, defaultSort, query} = useSelector(
    (state: RootState) => state.sucursales
  );
  const hasPartnerName = query.includes("partnerName");

  const setSort = (sort: string) => {
    const sortOrder =
      sortBy !== sort ? "asc" : defaultSort === "asc" ? "desc" : "asc";
    dispatch(setSortBy(sort));
    dispatch(setPage(0));
    dispatch(setDefaultSort(sortOrder));
  };

  const getIconSort = () => {
    return (
      <i
        className={`fa-solid fa-sort-${defaultSort === "asc" ? "up" : "down"}`}
      />
    );
  };
  const defaultStyle = { cursor: "pointer" };


  return (
    <div className="table-responsive mb-3" style={{ overflowY: "auto", maxHeight: '500px' }}>
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead style={{ ...defaultStyle }}>
          <tr style={{ ...fixedHeadStyle } as React.CSSProperties}>
            <th style={{ ...fixedStyle } as React.CSSProperties} style={{ width: "10%" }} onClick={() => setSort("id")} >ID {sortBy === "id" && getIconSort()}</th>
            <th style={{ width: "23%" }} onClick={() => setSort("name")}>Sucursales{sortBy === "name" && getIconSort()}</th>
            <th style={{ width: "22%" }}onClick={() => setSort("templateBranch")}>Plantilla{sortBy === "templateBranch" && getIconSort()}</th>
            <th style={{ width: "22%" }}onClick={() => setSort("isActive")}>Activa{sortBy === "isActive" && getIconSort()}</th>
            <th className="text-center" style={{ width: "20%" }}>
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          {sucursalesList && sucursalesList.length > 0 ? (
            sucursalesList
              .filter((item) => {
                if (allowed) {
                  return item;
                } else {
                  return item.name === user.branch;
                }
              })
              .map((item, index) => (
                <tr key={index}>
                  <td style={{ ...fixedStyle } as React.CSSProperties}>{item.id}</td>
                  <td style={{ width: "23%" }}>
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ width: "100%" }}
                    >
                      <span
                        style={{
                          marginRight: "20px",
                          flex: 1,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.name}
                      </span>
                      {item.candidates &&
                      item.candidates.length > 0 &&
                      hasPartnerName ? (
                        <div className="d-flex align-items-center">
                          {item.candidates
                            .slice(0, 2)
                            .map((candidate, index) => {
                              const colors = [
                                "bg-warning",
                                "bg-success",
                                "bg-dark",
                              ];

                              return (
                                <Tooltip
                                  title={`${candidate.person.firstName} ${candidate.person.lastName}`}
                                  arrow
                                  key={candidate.id}
                                >
                                  <div
                                    className="avatar"
                                    style={{ marginRight: "5px" }}
                                  >
                                    <div
                                      className={`avatar ${
                                        colors[index % colors.length]
                                      } text-white d-flex align-items-center justify-content-center`}
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        borderRadius: "50%",
                                      }}
                                    >
                                      {candidate.person.firstName.charAt(0)}
                                      {candidate.person.lastName.charAt(0)}
                                    </div>
                                  </div>
                                </Tooltip>
                              );
                            })}
                          {item.candidates.length > 2 && (
                            <Tooltip
                              title={item.candidates
                                .slice(2)
                                .map(
                                  (candidate) =>
                                    `(${candidate.person.firstName} ${candidate.person.lastName})`
                                )
                                .join(", ")}
                              arrow
                            >
                              <div
                                className="avatar bg-secondary text-white d-flex align-items-center justify-content-center"
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "50%",
                                }}
                              >
                                +{item.candidates.length - 2}
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </td>
                  <td style={{ width: "22%" }}>
                    {item?.templateBranch?.name ?? "Sin plantilla"}
                  </td>
                  <td style={{ width: "22%" }}>
                    {item.isActive ? "Activa" : "Desactiva"}
                  </td>
                  <td className="text-center" style={{ width: "20%" }}>
                    {allowed && (
                      <React.Fragment>
                        <Tooltip title={`Editar`} arrow>
                          <a
                            className="btn btn-primary m-2"
                            onClick={() => {
                              setRecord(item);
                              navigate(`editar/${item.id}`);
                            }}
                          >
                            <span className="d-none d-sm-inline"></span>
                            <i className="fa-solid fa-pen-to-square"></i>
                          </a>
                        </Tooltip>
                      </React.Fragment>
                    )}
                    {/* Colaboradores */}
                    <Tooltip title={`Colaboradores`} arrow>
                      <a
                        className="btn btn-primary m-2"
                        onClick={() => {
                          navigate(`colaboradores/${item.id}`);
                        }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa-solid fa-users"></i>
                      </a>
                    </Tooltip>

                    {/* Plantilla de puestos contra colaboradores */}
                    <Tooltip
                      title={`Plantilla de puestos contra colaboradores`}
                      arrow
                    >
                      <a
                        className="btn btn-verde m-2"
                        onClick={() => {
                          setRecord(item);
                          navigate(`reporte-plantilla/${item.id}`);
                        }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa-solid fa-arrows-down-to-people"></i>
                      </a>
                    </Tooltip>

                    {/* Reporte de chequeo */}
                    <Tooltip title={`Reporte de chequeo`} arrow>
                      <a
                        className="btn btn-amarillo m-2"
                        onClick={() => {
                          navigate(`chequeo/${item.id}`);
                        }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa-solid fa-list"></i>
                      </a>
                    </Tooltip>

                    {/* Cobertura puestos */}
                    <Tooltip title={`Cobertura puestos`} arrow>
                      <a
                        className="btn btn-verde m-2"
                        onClick={() => {
                          setRecord(item);
                          navigate(`cobertura-puestos/${item.id}`);
                        }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa-solid fa-list-check"></i>
                      </a>
                    </Tooltip>

                    {/* Horario Pendiente */}
                    <Tooltip title={`Horario`} arrow>
                      <a
                        className="btn btn-amarillo m-2"
                        onClick={() => {
                          setRecord(item);
                          navigate(`horario/${item.id}`);
                        }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa-solid fa-calendar-days"></i>
                      </a>
                    </Tooltip>

                    {/* Resumen horario (Horario en forma de excel) */}
                    <Tooltip title={`Resumen horario`} arrow>
                      <a
                        className="btn btn-azul m-2"
                        onClick={() => {
                          setRecord(item);
                          navigate(`resumen-horario/${item.id}`);
                        }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa-solid fa-list-check"></i>
                      </a>
                    </Tooltip>

                    {/* Inasistencias */}
                    {/* <Tooltip title={`Inasistencias`} arrow>
                      <a
                        className="btn btn-rojo m-2"
                        onClick={() => {
                          setRecord(item);
                          navigate(`inasistencias/${item.id}`);
                        }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa-solid fa-clipboard-list"></i>
                      </a>
                    </Tooltip> */}

                    {/* Incidencias */}
                    <Tooltip title={`Incidencias`} arrow>
                      <a
                        className="btn btn-azul m-2"
                        onClick={() => {
                          setRecord(item);
                          navigate(`incidencias/${item.id}`);
                        }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </a>
                    </Tooltip>

                    {/* Resumen de asistencias por turno (Pendiente tiempo real) */}
                    <Tooltip title={`Resumen de asistencias por turno`} arrow>
                      <a
                        className="btn btn-verde m-2"
                        onClick={() => {
                          setRecord(item);
                          navigate(`resumen-horario-asistencia/${item.id}`);
                        }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa-solid fa-clipboard-list"></i>
                      </a>
                    </Tooltip>

                    {/* Asistencia(horario en una vista) (Pendiente tiempo real) */}
                    <Tooltip title={`Asistencias`} arrow>
                      <a
                        className="btn btn-verde m-2"
                        onClick={() => {
                          setRecord(item);
                          navigate(`asistencias/${item.id}/horario`);
                        }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa-solid fa-clipboard-user"></i>
                      </a>
                    </Tooltip>

                    {/* Traspasos */}
                    <Tooltip title={`Traspasos`} arrow>
                      <a
                        className="btn btn-amarillo m-2"
                        onClick={() => {
                          setRecord(item);
                          navigate(`traspasos/${item.id}`);
                        }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa-solid fa-shuffle"></i>
                      </a>
                    </Tooltip>

                    {/* Reporte turnos
                    <Tooltip title={`Reporte de turnos`} arrow>
                      <a
                        className="btn btn-azul m-2"
                        onClick={() => {
                          setRecord(item);
                          navigate(`reporte/${item.id}`);
                        }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa-solid fa-table-list"></i>
                      </a>
                    </Tooltip> */}

                    {/* Banner */}
                    <Tooltip title={`Editar banner`} arrow>
                      <a
                        className="btn btn-verde m-2"
                        onClick={() => {
                          setModalText(true);
                          setBranchId(item.id);
                        }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa-solid fa-message"></i>
                      </a>
                    </Tooltip>

                    {/* Configuracion turnos */}
                    <Tooltip title={`Editar horarios`} arrow>
                      <a
                        className="btn btn-secondary m-2"
                        onClick={() => {
                          setShowEditTimeModal(true);
                          setBranchId(item.id);
                        }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa-solid fa-clock"></i>
                      </a>
                    </Tooltip>

                    {/* Cumpleaños */}
                    <Tooltip title={`Cumpleaños`} arrow>
                      <a
                        className="btn btn-azul m-2"
                        onClick={() => {
                          setRecord(item);
                          navigate(`birthdates/${item.id}`);
                        }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa-solid fa-cake-candles"></i>
                      </a>
                    </Tooltip>
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <td colSpan={5}>
                <div className="py-4">
                  <div className="d-flex">
                    <img src={EmptyImage} alt="" className="mx-auto w-250px" />
                  </div>
                  <h5 className="text-secondary text-center fs-20px">
                    No existen sucursales aún
                  </h5>
                  <h5 className="text-center text-secondary fw-400 fs-15px">
                    Prueba añadiendo una en
                  </h5>
                  <h5 className="text-center text-secondary fw-400">
                    el botón de 'Agregar sucursales'
                  </h5>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {showEditModal && (
        <ModalContainer
          open={showEditModal}
          title="Editar sucursal"
          closeFn={() => setShowEditModal(false)}
          children={
            <Form
              closeFn={() => {
                setShowEditModal(false);
                refetch();
              }}
              tapSelected="branches"
              dataToEdit={record || {}}
            />
          }
        />
      )}

      {showDeleteModal && (
        <ModalContainer
          open={showDeleteModal}
          title={record?.isActive ? "Desactivar sucursal" : "Activar sucursal"}
          closeFn={() => setShowDeleteModal(false)}
          children={
            <Form
              closeFn={() => {
                setShowDeleteModal(false);
                refetch();
              }}
              tapSelected="branches"
              dataToDelete={record || null}
              dataToEdit={{}}
            />
          }
        />
      )}

      <ModalContainer
        open={showEditTimeModal}
        title={"Editar horarios sucursal"}
        closeFn={() => setShowEditTimeModal(false)}
        children={
          <EditTime
            branchId={branchId}
            closeFn={() => setShowEditTimeModal(false)}
            refreshFn={refetch}
            setLoading={() => {}}
          />
        }
      />

      <ModalContainer
        open={modalText}
        title={"Editar mensaje de la sucursal"}
        closeFn={() => setModalText(false)}
        children={
          <EditBranchMessage
            branchId={branchId}
            closeFn={() => setModalText(false)}
          />
        }
        size="lg"
      />
    </div>
  );
};

export default TableSucursales;
