import React from "react";
import IFormikProps from "../../interfaces/others/IFormikProps";

const CustomSelectField = ({
  formik,
  field,
  fieldName = "",
  list,
  label,
  sm = 9,
  smLabel = 3,
  onChange = () => {},
  placeholder = label,
  updateFormik = (e) => {},
  disabled = false,
  otherPlaceHolder = false,
  required = false,
}: IFormikProps & {
  field: string;
  list: any[];
  label: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  sm?: number;
  placeholder?: string;
  updateFormik?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  smLabel?: number;
  disabled?: boolean;
  otherPlaceHolder?: boolean;
  required?: boolean;
  fieldName?: string;
}) => {
  const error = formik.touched[field] && Boolean(formik.errors[field]);

  return (
    <React.Fragment>
      <div className={`row mb-4 fs-13px col-md-12`}>
        <label
          className={`form-label col-form-label col-md-${smLabel} text-end`}
          style={{ color: error ? "red" : "inherit" }}
        >
          {label}
          {required && (
          <span style={{ top: 0, right: 0, color: error ? "red" : "inherit" }}>
            *
          </span>
        )}
        </label>

        <div className={`col-md-${sm}`}>
          <select
            className={`form-select form-control fs-14px ${error ? "is-invalid" : ""}`}
            id={field}  // Cambia el id a field
            name={field} // Cambia el name a field
            onChange={(e) => {
              formik.setFieldValue(field, Number(e.target.value));
              const element = list.find((_) => _.id === Number(e.target.value)).name;
              if (fieldName) {
                formik.setFieldValue(fieldName, element);
              }
              onChange(e);
              updateFormik(e);
            }}
            style={{
              color: error ? "red" : "inherit",
              width: "100%",
              display: "block",
            }}
            value={formik.values[field] !== -99 ? formik.values[field] : "_"}
            disabled={disabled}
          >
            <option value="_" disabled>
              {!otherPlaceHolder
                ? `-- Selecciona un ${placeholder} --`
                : placeholder}
            </option>
            {list.map((role) => (
              <option key={role.id} value={role.id}>
                {role.name || role.title} 
              </option>
            ))}
          </select>
          {error && (
            <div className="invalid-feedback" style={{ marginTop: "1px", display: "flex", marginLeft: "0.25em" }}>
              <>{formik.errors[field]}</>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomSelectField;
