import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/es";
import { RootState } from "../../../../store";
import EmptyImage from "../../../../assets/img/ic_content.svg";

moment.locale("es");

const TableChequeo = () => {
  const { records } = useSelector((state: RootState) => state.chequeo);

  const calcularMinutosExtra = (horaSalidaHorario: string, horaSalidaRegistro: string) => {
    if (!horaSalidaHorario || !horaSalidaRegistro) return "";
    const start = moment(horaSalidaHorario, "HH:mm");
    const end = moment(horaSalidaRegistro, "HH:mm:ss");
    const diff = end.diff(start, "minutes");
    return diff > 0 ? diff : 0;
  };

  return (
    <div className="table-responsive mb-3">
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead>
          <tr>
            <th>Id</th>
            <th>Colaborador</th>
            <th>Posición</th>
            <th>Día de la semana</th>
            <th>Turno</th>
            <th>Hora entrada horario</th>
            <th>Hora salida horario</th>
            <th>Hora entrada registrada</th>
            <th>Hora salida registrada</th>
            <th>Minutos extra</th>
            <th>Estatus de asistencia</th>
            <th>Es reemplazo</th>
          </tr>
        </thead>
        <tbody>
          {records && records.length > 0 ? (
            records.map((item, index) => {
              const person = item.partner.person;
              const horaEntradaHorario = item.startTime?.split(" - ")[0];
              const horaSalidaHorario = item.endTime?.split(" - ")[1];
              const horaEntradaRegistrada = item.assistanceCheck ? moment(item.assistanceCheck.checkTime, "HH:mm:ss").format("HH:mm") : "";
              const horaSalidaRegistrada = item.assistanceCheckout ? moment(item.assistanceCheckout.checkTime, "HH:mm:ss").format("HH:mm") : "";
              const minutosExtra = calcularMinutosExtra(horaSalidaHorario, horaSalidaRegistrada);

              return (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{`${person.firstName} ${person.lastName} ${person.secondLastName}`}</td>
                  <td>{item.positionName}</td>
                  <td>{item.dayOfWeek}</td>
                  <td>{item.shift}</td>
                  <td>{horaEntradaHorario || ""}</td>
                  <td>{horaSalidaHorario || ""}</td>
                  <td>{horaEntradaRegistrada || ""}</td>
                  <td>{horaSalidaRegistrada || ""}</td>
                  <td>{minutosExtra !== "" ? `${minutosExtra} min` : ""}</td>
                  <td>{item.assistanceStatus}</td>
                  <td>{item.isReplacement ? "Sí" : "No"}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={12}>
                <div className="py-4">
                  <div className="d-flex">
                    <img src={EmptyImage} alt="" className="mx-auto w-250px" />
                  </div>
                  <h5 className="text-secondary text-center fs-20px">Aún no existen datos</h5>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableChequeo;
