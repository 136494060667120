import React from "react";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <div className="brand p-2">
            <div className="w-50px">
              <Link to="/">
                <img
                  src="../../../../assets/img/logo/logo_chili_rojo.png"
                  alt=""
                  className="w-100"
                />
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <div
        style={{
          paddingBottom: "10%",
          userSelect: "none",
          // webkitUserSelect: "none",
          // mozUserSelect: "none",
          msUserSelect: "none",
        }}
        onCopy={(e) => e.preventDefault()}
        className="no-print"
      >
        <div className="col">
          <h1 style={{ marginTop: "1.5%", textAlign: "center" }}>
            Política de Privacidad para la ChiliApp
          </h1>

          <div
            style={{
              marginTop: "1.5%",
              paddingLeft: "15%",
              paddingRight: "15%",
              fontSize: "18px",
            }}
          >
            <p
              style={{
                marginTop: "1.5%",
                marginBottom: "1.5%",
                textAlign: "end",
                fontSize: "15px",
              }}
            >
              Última actualización: 13 de noviembre de 2024
            </p>
            <p>
              En <span style={{ fontWeight: "bold" }}>Chiliguajili</span>,
              respetamos y valoramos la privacidad de nuestros usuarios. Esta
              Política de Privacidad describe cómo recopilamos, utilizamos y
              protegemos sus datos personales a través de nuestra aplicación.
            </p>
            <span style={{ fontWeight: "bold" }}>
              1. Información que recopilamos
            </span>
            <p>
              <span style={{ fontWeight: "bold" }}>Ubicación</span>: Solicitamos
              acceso a su ubicación para confirmar que se encuentra en la
              sucursal de Chiliguajili. Este acceso se utiliza exclusivamente
              para validar su asistencia en la sucursal.
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Fotos y Galería</span>:
              Pedimos acceso a su cámara y galería para permitirle subir su
              expediente laboral y otros documentos requeridos por la empresa.
            </p>

            <span style={{ fontWeight: "bold" }}>2. Uso de la información</span>
            <p>
              Toda la información que recopilamos a través de la aplicación es
              utilizada exclusivamente para fines internos de la empresa. En
              particular:
              <ul>
                <li>
                  La ubicación se utiliza para registrar su asistencia cuando
                  esté físicamente en la sucursal.
                </li>
                <li>
                  Las fotos y otros documentos se almacenan en su expediente
                  laboral dentro de los sistemas internos de Chiliguajili.
                </li>
              </ul>
            </p>

            <span style={{ fontWeight: "bold" }}>
              3. Protección de sus datos
            </span>
            <p>
              Nos comprometemos a proteger su información personal. Utilizamos
              tecnologías de cifrado para asegurar que sus datos se mantengan
              seguros en nuestros sistemas. Además, tomamos medidas técnicas y
              organizativas para evitar accesos no autorizados y proteger la
              confidencialidad de su información.
            </p>

            <span style={{ fontWeight: "bold" }}>
              4. No compartimos su información
            </span>
            <p>
              Chiliguajili no compartirá, venderá ni divulgará su información
              personal a terceros. Sus datos permanecerán dentro de nuestra
              empresa y serán utilizados solo para los fines establecidos en
              esta política.
            </p>

            <span style={{ fontWeight: "bold" }}>5. Sus derechos</span>
            <p>
              Tiene derecho a solicitar información sobre los datos personales
              que hemos recopilado y a corregir cualquier dato incorrecto.
              También puede solicitar la eliminación de su información personal
              en cualquier momento, sujeto a los requisitos legales aplicables.
            </p>

            <span style={{ fontWeight: "bold" }}>
              6. Cambios en la Política de Privacidad
            </span>
            <p>
              Nos reservamos el derecho de modificar esta Política de Privacidad
              en cualquier momento. Si realizamos cambios significativos, le
              informaremos a través de la aplicación y actualizaremos la fecha
              de "Última actualización" al inicio de este documento.
            </p>

            <span style={{ fontWeight: "bold" }}>Contacto</span>
            <p>
              Si tiene alguna pregunta o inquietud sobre esta Política de
              Privacidad o el uso de sus datos, puede ponerse en contacto con
              nosotros en: <span>chiliguajili.desarrollo@gmail.com</span>
            </p>
          </div>
        </div>
      </div>

      <div
        className="h-75px"
        style={{
          backgroundImage: "url(../../../assets/img/footer/footer-chili.png)",
          backgroundRepeat: "repeat-x",
          position: "fixed",
          bottom: "0",
          left: "0",
          width: "100%",
          marginTop: "2.5%",
        }}
      />
      <style>{`
        @media print {
          .no-print {
            display: none;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export default Privacy;
